<template>
    <!-- Footer Fold -->
    <footer class="footer-fold site-max-width">
        <svg
            class="tab"
            xmlns="http://www.w3.org/2000/svg"
            width="170"
            viewBox="0 0.001 222.496 57.469"
        >
            <path
                d="M222.496 57.47 216.35 8.606a4.616 4.616 0 0 0-.031-.18c-.22-1.166-1.726-7.633-8.266-8.36a11.124 11.124 0 0 0-1.23-.066H15.674a10.435 10.435 0 0 0-2.316.248c-5.86 1.33-7.08 7.577-7.2 8.291a2.943 2.943 0 0 0-.01.067L0 57.469h222.496Z"
                fill="#efeade"
                fill-rule="evenodd"
                data-name="Path 17"
            />
        </svg>

        <div class="container" v-if="$route.meta && $route.meta.lang == 'fr'">
            <div class="links-grid">
                <div class="links-item">
                    <h3 v-if="$route.meta && $route.meta.lang == 'fr'" class="small-title">Pour nous joindre</h3>
                    <h3 v-else class="small-title">Contact Us</h3>
                    <address>
                        612, rue Saint-Jacques, 7e étage <br />
                        Montréal (Québec) <br />
                        Canada
                    </address>
                    <a href="mailto:info@qolab.ca" class="mail">info@qolab.ca</a>
                    <!-- <a href="tel:(514) 526-9251 #5805" class="phone">(514) 526-9251 #5805</a> -->
                </div>
                <div class="links-item">
                    <h3 v-if="$route.meta && $route.meta.lang == 'fr'" class="small-title">Pour travailler avec nous</h3>
                    <h3 v-else class="small-title">Work with us</h3>
                    <!-- <a href="tel:(514) 526-9251 #5805" class="phone">(514) 526-9251 #5805</a> -->
                    <a v-if="$route.meta && $route.meta.lang == 'fr'" href="https://careers.smartrecruiters.com/Videotron/qolab" target="_blank" rel="noopener noreferrer" class="mail">Consulter les offres d’emploi </a>
                    <a v-else href="https://careers.smartrecruiters.com/Videotron/qolab" target="_blank" rel="noopener noreferrer" class="mail">View job offers</a>
                </div>
            </div>
            <div class="copyright">
                <div class="reseaux">
                    <a
                        href="https://www.instagram.com/qolab_ca/?hl=en"
                        class="reseau-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Instagram"
                    >
                        Instagram
                    </a>
                    <a
                        href="https://www.linkedin.com/company/communications-qolab/"
                        class="reseau-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="LinkedIn"
                    >
                        LinkedIn
                    </a>
                </div>
                <p v-if="$route.meta && $route.meta.lang == 'fr'" class="small-text">@ Qolab  2024. Tous droits réservés.</p>
                <p v-else class="small-text">@ Qolab  2024. All rights reserved.</p>
            </div>
        </div>

        <div class="container" v-else>
            <div class="links-grid">
                <div class="links-item">
                    <h3 class="small-title">Toronto</h3>
                    <address>
                       88 Queens Quay W #200 <br>
                       Toronto, ON
                    </address>

                    <h3 class="small-title">Montreal</h3>
                    <address>
                        612 rue Saint-Jacques <br>
                        Montreal, QC
                    </address>


                    <!-- <a href="tel:(514) 526-9251 #5805" class="phone">(514) 526-9251 #5805</a> -->
                </div>
                <div class="links-item">
                    <h3 class="small-title">Let’s collaborate!</h3>
                    <p>To reach out: <a href="mailto:info@qolab.ca" class="mail">info@qolab.ca</a></p>
                    <br><br>
                    <address>
                        Marie-Claude Desjardins <br>
                        General Manager<br>
                        Business Development and Content<br>
                        Qolab &amp; MELS (Québecor)<br>
                        <a href="tel:647 228-2805">647 228-2805</a>
                    </address>
                </div>
            </div>
            <div class="copyright">
                <div class="reseaux">
                    <a
                        href="https://www.instagram.com/qolab_ca/?hl=en"
                        class="reseau-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Instagram"
                    >
                        Instagram
                    </a>
                    <a
                        href="https://www.linkedin.com/company/communications-qolab/"
                        class="reseau-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="LinkedIn"
                    >
                        LinkedIn
                    </a>
                </div>
                <p v-if="$route.meta && $route.meta.lang == 'fr'" class="small-text">@ Qolab  2024. Tous droits réservés.</p>
                <p v-else class="small-text">@ Qolab  2024. All rights reserved.</p>
            </div>
        </div>

        <div v-if="$route.meta && $route.meta.lang == 'fr'" class="legal-notice" style="padding: 30px; background: rgba(0, 0, 0, 0.0275); font-size: 12px; text-align: center;">
            <strong>RESPONSABLE DE LA PROTECTION DES DONNÉES PERSONNELLES</strong>
            <p>Jonathan Lee Hickey, Vice-président principal, affaires juridiques et secrétariat corporatif et responsable de la protection des renseignements personnels : <a href="mailto:vieprivee@quebecor.com">vieprivee@quebecor.com</a></p>
        </div>
        <div v-else class="legal-notice" style="padding: 30px; background: rgba(0, 0, 0, 0.0275); font-size: 12px; text-align: center;">
            <strong>Jonathan Lee Hickey</strong>
            <p>Senior Vice President, Legal Affairs and Corporate Secretary and Privacy Officer <a href="mailto:vieprivee@quebecor.com">vieprivee@quebecor.com</a></p>
        </div>
    </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

export default {
    name: 'Footer',

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    setTimeout(() => {
                        setTimeout(() => {
                            // this.gsapActivateFooter()
                        }, 1000)
                    }, 1000)
                }
            }
        }
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
